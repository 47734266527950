/* eslint-disable */
import "./Shop.css";
import { Icon } from "@blueprintjs/core";
import { Checkbox, useToast } from "@chakra-ui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { JobType, MultipleProductInfo, RankingDetails, SearchState, SelectionState, Stores } from "../types";
import axios from "axios";
import { ProductCard } from "../components/ProductCard";
import CircularProgress from "@mui/material/CircularProgress";
import { Selector } from "../components/Selector";
import { useSelectionContext } from "../hooks";
import { _INITIAL_RANKING_DETAILS, BACKEND_URL, initialSelectionState } from "../resources";
import { useStateObject } from "../hooks/useStateObject";
import { SearchSelector } from "../components/SearchSelector";
import { MultiSelector } from "../components/MultiSelector";
import { getAlert, getBrandsCategory, getBrandsForSubCategory, getSubCategories } from "../utils";

export type CategoryMapping = { [key: string]: { sub_categories: { [key: string]: { [key: string]: { [key: string]: true } } }, unit: string } }
export type BrandMapping = { [key: string]: string[] }


export function Shop() {
  const { selectionState, setSelectionState } = useSelectionContext();
  const [searchState, setSearchState] = useStateObject<SearchState>(
    selectionState["current"]
  );
  const [isPhysicalShop, setIsPhysicalShop] = useState(true);
  const searchRef = useRef(searchState);
  const [products, setProducts] = useState<MultipleProductInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryMapping, setCategoryMapping] = useState<CategoryMapping>({});
  const [clearTrigger, setClearTrigger] = useState<boolean>(false)
  const [hasSearched, setHasSearched] = useState(false)
  const [placeholders, setPlaceholders] = useState(['-', '-'])
  const [rankingDetails, setRankingDetails] = useState<RankingDetails>(_INITIAL_RANKING_DETAILS)
  const toast = useToast()


  const minVolumeValue = searchState.minVolume == -Infinity ? "" : searchState.minVolume ?? ""
  const maxVolumeValue = searchState.maxVolume == Infinity ? "" : searchState.maxVolume ?? ""
  const volumeSet = searchState.minVolume != -Infinity || searchState.maxVolume != Infinity

  const categories = Object.keys(categoryMapping)
  const subCategories = Object.keys(categoryMapping[searchState.category]?.sub_categories ?? {}).filter(subCat => subCat != 'blank')
  const validBrands = useMemo(() => {
    let brands = {}
    for (const store in searchState.store) {
      if (searchState.store[store]) {
        for (const subCategory in searchState.subCategories) {
          brands = { ...brands, ...categoryMapping[searchState.category]?.sub_categories[subCategory]?.[store] ?? {} }
        }
        brands = { ...brands, ...categoryMapping[searchState.category]?.sub_categories['blank']?.[store] ?? {} }
      }
    }
    return Object.keys(brands)
  }, [searchState.store, categories, subCategories])

  const fetchProductsByCategories = async () => {
    if (!isSearchable) {
      toast({
        title: "You're missing something...",
        description: getAlert(searchState),
        status: "error",
        duration: 5000, // duration in milliseconds, after which toast disappears
        isClosable: true, // allows user to close the toast manually
        position: "top", // where the toast will appear
        variant: "subtle"
      });
      return
    }
    setHasSearched(true)
    setIsLoading(true);
    const payload = {
      jobType: JobType.CATEGORY_RANK_MULTIPLE,
      params: {
        barcode: searchState.barcode,
        category: searchState.category,
        subCategories: Object.keys(searchState.subCategories),
        stores: searchState.store,
        minVolume: searchState.minVolume,
        maxVolume: searchState.maxVolume,
        brands: Object.keys(searchState.brands),
      },
    };
    const res = await axios.post(BACKEND_URL + '/job/initiate', payload);
    if (!(res.status === 200)) return;
    if (res.data.products) {
      setProducts(res.data.products);
      setIsLoading(false);
      setRankingDetails(res.data.rankingDetails)
    }
  };

  const handleMinMaxChange = (val: React.ChangeEvent<HTMLInputElement>, isMin: boolean) => {
    const value =
      val.target.value === ""
        ? null
        : isNaN(Number(val.target.value)) ? null : Number(val.target.value)
    setSearchState(isMin ? {
      minVolume: value,
    } : { maxVolume: value });
  }


  function handleStoreChange(store: string) {
    const { store: currentStore } = searchState;
    if (store === "onlineShop") {
      setIsPhysicalShop(false);
      setSearchState({
        store: {
          woolworths: true,
          checkers: true,
        },
      });
      return;
    } else {
      currentStore[store] = !currentStore[store];
      setSearchState({
        store: currentStore,
      });
    }
  }

  function handleCategoryChange(category: string) {
    let currentCategory = searchState.category;
    if (currentCategory === category) {
      setSearchState({
        category: "",
        subCategories: {},
        brands: {}
      });
    } else {
      const subCategories = getSubCategories(categoryMapping, category)
      const brands = getBrandsCategory(categoryMapping, category, searchState)
      setSearchState({
        category: category,
        subCategories,
        brands
      });
    }
  }

  function handleSubCategoryChange(subCategory: string) {
    let currentsubCategories = { ...searchState.subCategories }
    if (currentsubCategories[subCategory]) {
      delete currentsubCategories[subCategory]
    } else {
      currentsubCategories[subCategory] = true
    }
    const brands = getBrandsForSubCategory(categoryMapping, Object.keys(currentsubCategories), searchState)
    setSearchState({ subCategories: currentsubCategories, brands })
  }

  function handleCategoryClear() {
    setSearchState({ category: '', subCategories: {}, brands: {} })
  }

  function handleSelectAllSubCategory() {
    const subCategories = getSubCategories(categoryMapping, searchState.category)
    const brands = getBrandsCategory(categoryMapping, searchState.category, searchState)
    setSearchState({
      subCategories,
      brands
    });
  }

  function handleSubCategoryClear() {
    setSearchState({ subCategories: {}, brands: {} })
  }

  function handleSelectAllBrands() {
    const brands = getBrandsForSubCategory(categoryMapping, Object.keys(searchState.subCategories), searchState)
    setSearchState({ brands })
  }

  function handleBrandsClear() {
    setSearchState({ brands: {} })
  }

  function handleBrandChange(brand: string) {
    let currentBrands = searchState.brands;
    if (currentBrands[brand]) {
      delete currentBrands[brand]
      setSearchState({
        brands: currentBrands,
      });
    } else {
      currentBrands[brand] = true
      setSearchState({
        brands: currentBrands
      });
    }
  }


  const clearResults = () => {
    setProducts([]);
    setHasSearched(false)
    setSearchState(initialSelectionState["current"]);
    handleBrandsClear()
    handleSubCategoryClear()
    setClearTrigger(prev => !prev)
  };


  const isSearchable = useMemo(() => {
    const isStoreSelected = isPhysicalShop
      ? Object.values(searchState.store).some((val) => val)
      : true;
    const isBarcodeSelected = !!searchState.barcode
    const isCategorySelected = !!searchState.category
    const isSubCategorySelcted = Object.keys(searchState.subCategories).length || getSubCategories(categoryMapping, searchState.category)['blank']
    const isBrandSelected = Object.keys(searchState.brands).length
    return isStoreSelected && (isBarcodeSelected || (isCategorySelected && isSubCategorySelcted && isBrandSelected))
  }, [searchState])

  useEffect(() => {
    searchRef.current = searchState;
  }, [searchState]);

  /* eslint-disable */
  useEffect(() => {
    if (isSearchable) {
      fetchProductsByCategories();
    }
    return () => {
      setSelectionState((prev: SelectionState) => ({
        ...prev,
        current: searchRef.current,
      }));
    };
  }, []);
  /* eslint-enable */

  useEffect(() => {
    const fetchProductMapping = async () => {
      const payload = {
        jobType: JobType.PRODUCT_MAPPING,
        params: {}
      };
      const res = await axios.post(BACKEND_URL + '/job/initiate', payload);
      if (!(res.status === 200)) return;
      if ('error' in res.data) {
        console.error(res.data.error)
        return
      }
      if (res.data) {
        setCategoryMapping(res.data)
      }
    }
    fetchProductMapping()
  }, [])



  return (
    <div className="page_container">

      <div className="search_container">
        <div className="store_content_container">
          <span className="text_container">
            <Icon icon="shop" size={12} color="#DDD" />
            <text className="shop_text">Select your stores</text>
          </span>
          <div className="store_dropdown">
            <Checkbox
              isChecked={searchState.store[Stores.CHECKERS]}
              onChange={() => handleStoreChange(Stores.CHECKERS)}
              colorScheme="custom"
            >
              Checkers
            </Checkbox>
            <Checkbox
              isChecked={searchState.store[Stores.WOOLWORTHS]}
              onChange={() => handleStoreChange(Stores.WOOLWORTHS)}
              colorScheme="custom"
            >
              Woolworths
            </Checkbox>
          </div>
          <div className="store_dropdown"></div>
        </div>
        <div className="store_content_container">
          <span className="text_container">
            <Icon icon="barcode" color="#DDD" size={12} />
            <text className="shop_text">Describe an item</text>
          </span>
          <div className="search_input_container">
            <SearchSelector
              setSearchState={setSearchState}
              clearTrigger={clearTrigger}
            />
            <Selector
              placeholder={
                searchState.category ? searchState.category : "Category"
              }
              listItems={categories}
              handleSelectionChange={handleCategoryChange}
              handleSelectionClear={handleCategoryClear}
              selectedValue={searchState.category}
            />
            <MultiSelector
              placeholder={"Sub-categories"}
              listItems={subCategories}
              handleSelectionChange={handleSubCategoryChange}
              handleSelectionClear={handleSubCategoryClear}
              handleSelectAll={handleSelectAllSubCategory}
              selectedValues={searchState.subCategories}
            />
            <MultiSelector
              placeholder={"Brands"}
              listItems={validBrands}
              handleSelectionChange={handleBrandChange}
              handleSelectionClear={handleBrandsClear}
              handleSelectAll={handleSelectAllBrands}
              selectedValues={searchState.brands}
            />
            <span className="volume_container">
              <span className="volume_container_text">
                <span className="volume_min_max">
                  <text>Min:</text>
                  <input
                    type="text"
                    value={minVolumeValue}
                    onChange={(val) => handleMinMaxChange(val, true)}
                    onFocus={() => setPlaceholders(prev => ['', prev[1]])}
                    onBlur={() => setPlaceholders(prev => ['-', prev[1]])}
                    placeholder={placeholders[0]}
                  />
                </span>
                <span className="volume_min_max">
                  <text>Max:</text>
                  <input
                    type="text"
                    value={maxVolumeValue}
                    onChange={(val) => handleMinMaxChange(val, false)}
                    onFocus={() => setPlaceholders(prev => [prev[0], ''])}
                    onBlur={() => setPlaceholders(prev => [prev[0], '-'])}
                    placeholder={placeholders[1]}
                  />
                </span>
                <text>{categoryMapping[searchState.category]?.unit ?? "units"}</text>
              </span>
              <text onClick={() => setSearchState({ minVolume: -Infinity, maxVolume: Infinity })}>{volumeSet ? "X" : ""}</text>
            </span>
          </div>
        </div>
        <div className="button_container">
          <button
            className={
              isSearchable
                ? "store_search_button"
                : "store_search_button_disable"
            }
            onClick={fetchProductsByCategories}
          >
            Search
          </button>
          {products.length > 0 && (
            <div className="search_button_container">
              <button className={"store_clear_button"} onClick={clearResults}>
                Clear
              </button>
            </div>
          )}
        </div>

        {isLoading && (
          <div className="card_spinner">
            <CircularProgress color="inherit" size={50} />
          </div>
        )}
        {products.length > 0 && !isLoading && (
          <div className="store_content_container">
            <span className="text_container">
              <Icon icon="search" color="#ddd" size={12} />
              <text className="shop_text">Results</text>
            </span>

            <div className="shop_result_list">
              {products.map((product) => (
                <ProductCard product={product} searchState={searchState} rankingDetails={rankingDetails} />
              ))}
            </div>
          </div>
        )}
        {products.length === 0 && hasSearched && !isLoading && "No results found"}
      </div>
    </div>
  );
}
